import React from 'react';

const useStylesMainSidebar = (props) => {

  const getStyleConfig = () => {
    return {
      logoPlataform: {
        flex: '0 0 80px'
        ,textTransform: 'uppercase'
        ,fontWeight: 'bold'
        ,fontSize: 12
        ,letterSpacing: '1px'
        ,overflow: 'hidden'
        ,textOverflow: 'ellipsis'
        ,whiteSpace: 'nowrap'
        ,display: 'flex'
        ,flexDirection:'column'
        ,justifyContent:'center'
        ,alignItems: 'center'
      }
      ,boxTab: {
          display: 'flex'
          ,flexDirection: 'row'
          ,flexWrap: 'nowrap'
          ,alignItems: 'stretch'
      }
      ,imgLogo: {
          width: '42px'
          ,height: '42px'
      }
      ,boxApply: props.sx || {}
    }
  }

  const [style, setStyle] = React.useState(getStyleConfig(props));

  React.useEffect(()=>{
    setStyle(getStyleConfig(props));
  },[props.sx]);

  return style;

}

export default useStylesMainSidebar;


