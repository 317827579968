import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link, BrowserRouter as Router } from "react-router-dom";
import { matchPath } from "react-router";

const LinkTabs = (props) => {

  const {children} = props;
  const [value, setValue] = React.useState(null);
  const [basename, setBasename] = React.useState(null);

  React.useEffect(() => {
    if (props.basename){
      setBasename(props.basename === '/' ? '' : props.basename);
    }
  }, [props.basename]);

  React.useEffect(() => {

    if  (basename === null) return;

    const matchBase = matchPath(props.location.pathname, {
      path: `${props.basename}`
      ,exact: false
      ,strict: false
    });

    if (matchBase?.isExact === true){
      if (!value && props.defaultPath){
        if(props.linkItems.filter((link) => link.path === props.defaultPath && link.hidden !== true && link.disabled !== true).length){
          setValue(`${props.defaultPath}`);
          props.history.push(`${basename}${props.defaultPath}`)
        }
      }
    }
    else if(matchBase){
      const matchRoot = matchPath(props.location.pathname, {
        path: `${basename}/:path`
        ,exact: false
        ,strict: false
      });

      if (matchRoot){
        if (matchRoot.params.path){
          if(props.linkItems.filter((link) => link.path === `/${matchRoot.params.path}` && link.hidden !== true && link.disabled !== true).length){
            setValue(`/${matchRoot.params.path}`);
          }
        }
      }
    }
  })

  const handleChange = (event, newValue) => {
      setValue(newValue);
  };

  return (
    <Router basename={props.basename}>
      <Tabs
          value={value ?? '/'}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="icon label tabs"
          indicatorColor={props.color}
          textColor={props.color}
      >
        {props.linkItems.map((link, index) => (
          <Tab
            key={index + link.label}
            icon={link.icon}
            label={link.label}
            component={Link}
            exact={false}
            to={link.path}
            value={link.path}
            disabled={link.disabled}
            style={{display: link.hidden === true ? 'none' : 'flex'}}
          />
        ))}
      </Tabs>
      {children}
    </Router>
  );
}

LinkTabs.defaultProps = {
    linkItems: []
    ,onChangeValue: (value, newValue) => true
    ,basename: '/'
    ,defaultPath: null
    ,color: 'primary'
}

export default LinkTabs;
