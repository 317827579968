import React from 'react';
import LinkTabs from '../link-tabs';
import Main from '../Main';
import {createUseStyles} from 'react-jss';
import { Box } from '@mui/material';
import useStylesMainSidebar from '../../Styles/useStylesMainSidebar';

const LinkTabsPanels = (props) => {

  const useStyles = createUseStyles(useStylesMainSidebar(props));
  const classes = useStyles();

  const {logo, ...next} = props;

  return (
    <React.Fragment>
      <Box className={classes.boxTab}>
        <div className={classes.logoPlataform}>
            {props.logo ? <img src={props.logo} className={classes.imgLogo}/> : ''}
        </div>
        <LinkTabs {...next} />
      </Box>
      <Main {...next}/>
    </React.Fragment>
  );
}

LinkTabsPanels.defaulrProps = {
    linkItems: []
    ,minusHeight: '0px'
    ,overflowY: 'hidden'
    ,overflowX: 'hidden'
    ,logo: null
}

export default LinkTabsPanels;
