import React from 'react';
import RouteSwitchPanel from '@sistemas.mundo/route-switch-panel';
import ContainerPanel from '@sistemas.mundo/container-panel';

const RouteMain = (props) => {

  const {
      im
      ,onChangePanel
      ,onChangeKeyRef
      ,handleToggleSidebar
      ,itemsPanels
      ,linkItems
      ,rtl
      ,collapsed
      ,toggled
      ,...next
  } = props;

  return (
      <ContainerPanel
          alignItems='stretch'
          justifyContent='flex-start'
          minusHeight={props.minusHeight}
          overflowY={props.overflowY}
          overflowX={props.overflowX}
      >
        <RouteSwitchPanel
            {...next}
            itemsPanels={linkItems}
        />
      </ContainerPanel>
  );
};

RouteMain.defaulrProps = {
    handleToggleSidebar: () => true
    ,onChangeKeyRef: () => true
    ,itemsPanels: []
    ,defaultKeyRef: null
    ,minusHeight: '0px'
    ,overflowY: 'hidden'
    ,overflowX: 'hidden'
}

export default RouteMain;
